import axios from '@axios'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')

import {checkResponseStatus} from '../checkResponseStatus'


export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDatas(ctx, queryParams) {
      return new Promise((resolve, reject) => {
       axios
          .get('/api/v1/modules', { params: queryParams })
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
              
          }).catch(error => reject(error))
      })
    },
    addData(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/modules', requestData)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchData(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/modules/${id}`)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateData(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/modules/${requestData.id}`, requestData)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    changeActiveStatus(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/module/${requestData.id}/change-active-status`, requestData)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchFeatureOption(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/option/features')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
